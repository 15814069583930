<template>
  <section class="section-block card-container">
    <SectionTitle title="自定義區塊" :disabled="displayData.disabled" @edit="$router.push({ name: 'EditCustomSetting'})" />
    <el-form v-if="showContent" label-position="left" label-width="250px">
      <el-form-item v-for="(item, index) in displayData.fullContentSetting" :key="item.text" :label="`排序 ${index+1}`">
        <div class="text-[#5E5E5E]">
          <p class="text-sub font-bold">{{ item.title }}</p>
          <pre class="text-sub font-normal leading-[20.27px]">{{ item.text }}</pre>
        </div>
      </el-form-item>
    </el-form>
    <p v-else class="text-sub">您需要將預約頁面內容變更為完整內容版，方可設定說明文字內容，向消費者介紹店面。</p>
  </section>
</template>

<script>
import { computed, defineComponent } from 'vue'
import { get } from 'lodash'

export default defineComponent({
  name: 'CustomSetting',
  props: {
    configData: { type: Object, default: () => ({}) },
    updatePubAptConfig: { type: Function, default: () => {} },
  },
  setup (props) {
    const displayData = computed(() => {
      return {
        fullContentSetting: get(props, 'configData.fullContentSetting'),
        disabled: get(props, 'configData.homeMode') === 'normal',
      }
    })
    const showContent = computed(() => get(props, 'configData.fullContentSetting') && get(props, 'configData.fullContentSetting').length > 0)
    return { displayData, showContent }
  },
})
</script>

<style lang="postcss" scoped></style>
