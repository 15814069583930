<template>
  <section class="section-block card-container">
    <SectionTitle title="Banner 區塊" :disabled="displayData.disabled" @edit="router.push({ name: 'EditBannerSetting'})" />
    <div v-if="showImage" class="flex gap-[8px]">
      <UploadButton
        v-for="item in displayData.fullBannerSetting"
        :key="item.ImageId"
        cyUploadBtn="service-img-upload-btn"
        cyUploadedImg="service-img-uploaded"
        :img="item.Image"
        width="160px"
        height="90px"
        disabled
      />
    </div>
    <p v-else class="text-sub">您需要將預約頁面內容變更為完整內容版，方可設定 Banner 輪播區塊，讓消費者更了解店面。</p>
  </section>
</template>

<script>
import { computed, defineComponent } from 'vue'
import { get } from 'lodash'
import { useRouter } from 'vue-router/composables'
import UploadButton from '@/components/Button/UploadButton.vue'

export default defineComponent({
  name: 'BannerSetting',
  components: { UploadButton },
  props: {
    configData: { type: Object, default: () => ({}) },
    updatePubAptConfig: { type: Function, default: () => {} },
  },
  setup (props) {
    const router = useRouter()
    const displayData = computed(() => {
      return {
        fullBannerSetting: get(props, 'configData.fullBannerSetting'),
        disabled: get(props, 'configData.homeMode') === 'normal',
      }
    })
    const showImage = computed(() => get(props, 'configData.fullBannerSetting') && get(props, 'configData.fullBannerSetting').length > 0)
    return { displayData, router, showImage }
  },
})
</script>

<style lang="postcss" scoped>
::v-deep .upload-wrapper,
::v-deep .input-label,
::v-deep .disabled {
  ::v-deep &.disabled {
    @apply opacity-100;
  }
}
</style>
