import { admin2 } from './instance'

// 取得跨境預約確認提醒
export const FindPubAptConfirmReminder = async ({ shopId }) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/pubAptConfirmReminder`,
  })
}

// 更新跨境預約確認提醒
export const UpdatePubAptConfirmReminder = async ({ shopId, data }) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/pubAptConfirmReminder`,
    data,
  })
}

// 取得跨境預約設定
export const FindPubAptConfig = async ({ shopId }) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/pubAptConfig`,
  })
}

// 更新跨境預約設定
export const UpdatePubAptConfig = async ({ shopId, data }) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/pubAptConfig`,
    data,
  })
}

// 取得訪客預約訂購單列表
export const GetPubAptReservation = async ({
  shopId,
  visitorEmail,
  visitorName,
  hasVisitorAuthGoogle,
  regionCode,
  start,
  limit,
  createdAtStart,
  createdAtEnd,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/appointmentVisitorPreOrder`,
    params: {
      visitorEmail,
      visitorName,
      hasVisitorAuthGoogle,
      regionCode,
      start,
      limit,
      createdAtStart,
      createdAtEnd,
    },
  })
}

// 查詢訪客預約訂購單數量
export const GetPubAptReservationCount = async ({
  shopId,
  visitorEmail,
  visitorName,
  hasVisitorAuthGoogle,
  regionCode,
  createdAtStart,
  createdAtEnd,
  orderDateStart,
  orderDateEnd,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/appointmentVisitorPreOrder/count`,
    params: {
      visitorEmail,
      visitorName,
      hasVisitorAuthGoogle,
      regionCode,
      createdAtStart,
      createdAtEnd,
      orderDateStart,
      orderDateEnd,
    },
  })
}

// 查詢訪客預約訂購單地區代碼列表
export const GetRegionCodeList = async ({ shopId }) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/appointmentVisitorPreOrder/regionCodeList`,
  })
}

// 查詢訪客預約付款設定
export const GetPubAptPaymentConfig = async ({ shopId, displayName, start, limit }) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/pubAptPaymentConfig`,
    params: {
      displayName,
      start,
      limit,
    },
  })
}

// 新增訪客預約付款設定
export const CreatePubAptPaymentConfig = async ({ shopId, data }) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/pubAptPaymentConfig`,
    data,
  })
}

// 刪除訪客預約付款設定
export const DeletePubAptPaymentConfig = async ({ shopId, id }) => {
  return await admin2({
    method: 'DELETE',
    url: `/${shopId}/pubAptPaymentConfig/${id}`,
  })
}

// 更新訪客預約付款設定
export const UpdatePubAptPaymentConfig = async ({ shopId, id, data }) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/pubAptPaymentConfig/${id}`,
    data,
  })
}

// 查詢訪客預約發票設定
export const FindPubAptInvoiceConfig = async ({ shopId }) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/pubAptInvoiceConfig`,
  })
}

// 更新訪客預約發票設定
export const UpdatePubAptInvoiceConfig = async ({ shopId, data }) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/pubAptInvoiceConfig`,
    data,
  })
}

// 查詢訪客預約訂金設定
export const FindPubAptDepositConfig = async ({ shopId }) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/pubAptDepositConfig`,
  })
}

// 更新訪客預約訂金設定
export const UpdatePubAptDepositConfig = async ({ shopId, data }) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/pubAptDepositConfig`,
    data,
  })
}

// 更新訪客預約訂購單發票狀態
export const UpdatePubAptInvoiceStatus = async ({ shopId, id, invoiceStatus }) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/appointmentVisitorPreOrder/${id}/invoiceStatus`,
    data: {
      invoiceStatus,
    },
  })
}
