export const pubAptPaymentTypes = {
  tapPay: { name: 'TapPay線上金流', value: 'tapPay', type: 'third-part' },
//   jkos: { name: '街口支付', value: 'jkos', type: 'third-part' },
//   linepay: { name: 'LINE Pay', value: 'linepay', type: 'third-part' },
}

export const feeTypes = {
  deposit: {
    label: '訂金',
    prop: 'deposit',
    default: false,
  },
  cardSecret: {
    label: '授權信用卡',
    prop: 'cardSecret',
    help: '在預約時間結束後 1 週內，將跨境預約的預約訂單狀態改為「未到」時，系統將依據此設定向使用者扣除損失費用。',
    default: false,
  },
  none: {
    label: '不收費',
    prop: 'none',
    default: true,
  },
}
export const amountTypes = {
  fixed: { label: '固定金額', value: 'fixed' },
  percent: { label: '固定比率', value: 'percent' },
}
export const invoice3rdPlatformConfig = {
  ezpay: { label: 'ezpay', value: 'ezpay' },
}

export const invoiceStatusConfig = {
  pending: { label: '未開立', value: 'pending', tag: 'info' },
  issued: { label: '已開立', value: 'issued', tag: 'action' },
  voided: { label: '系統已作廢', value: 'voided', tag: 'info' },
  allowance: { label: '系統已折讓', value: 'allowance', tag: 'info' },
  markedVoided: { label: '標記已作廢', value: 'markedVoided', tag: 'danger' },
  markedAllowance: { label: '標記已折讓', value: 'markedAllowance', tag: 'danger' },
}

export const invoiceTypeConfig = {
  C: { label: '二聯式發票', value: 'C' },
  B: { label: '三聯式發票', value: 'B' },
}

export const paymentStatusConfig = {
  pending: { label: '扣款中', value: 'pending', tag: 'warning' },
  paid: { label: '扣款成功', value: 'paid', tag: 'action' },
  failed: { label: '扣款失敗', value: 'failed', tag: 'danger' },
  refund: { label: '已退款', value: 'refund', tag: 'warning' },
}

export const depositPaymnetStatusConfig = {
  paid: { label: '已付款', value: 'paid', tag: 'action' },
}

export const homeModeConfig = {
  normal: { label: '一般版', value: 'normal' },
  full: { label: '完整內容版', value: 'full' },
}
