<template>
  <section class="section-block card-container">
    <SectionTitle title="基本設定" @edit="showEditModal = true" />
    <el-form label-position="left" label-width="250px">
      <el-form-item label="產業類別是否顯示於 C 端">
        <div class="text-[#5E5E5E]">
          {{ displayData.isShowIndustry ? '開啟' : '關閉' }}
        </div>
      </el-form-item>
      <el-form-item label="預約頁面內容">
        <div class="text-[#5E5E5E]">
          {{ displayData.homeMode }}
        </div>
      </el-form-item>
    </el-form>
    <BasicSettingEditModal
      v-if="showEditModal"
      :configData="configData"
      :onSubmit="onSubmit"
      @close="showEditModal = false"
    />
  </section>
</template>

<script>
import { computed, defineComponent, ref } from 'vue'
import BasicSettingEditModal from './BasicSettingEditModal.vue'
import { get } from 'lodash'
import { homeModeConfig } from '@/config/pubApt'

export default defineComponent({
  name: 'BasicSetting',
  components: { BasicSettingEditModal },
  props: {
    configData: { type: Object, default: () => ({}) },
    updatePubAptConfig: { type: Function, default: () => {} },
  },
  setup (props) {
    const showEditModal = ref(false)
    const displayData = computed(() => {
      return {
        isShowIndustry: get(props, 'configData.isShowIndustry'),
        homeMode: get(homeModeConfig[get(props, 'configData.homeMode')], 'label'),
      }
    })

    const onSubmit = async (data) => {
      console.log('onSubmit', data)
      await props.updatePubAptConfig(data)
      showEditModal.value = false
    }

    return { displayData, showEditModal, onSubmit }
  },
})
</script>

<style lang="postcss" scoped></style>
